var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('a-card',{attrs:{"body-style":{ padding: '24px 32px' },"bordered":false}},[_c('a-form-item',{attrs:{"label":_vm.$t('field.supplierCompanyCode'),"labelCol":{ lg: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 10 }, sm: { span: 17 } }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'supplierCompanyData.supplierCompanyCode',
            {
              rules:( [
                { required: true, message: '请输入' + this.$t('field.supplierCompanyCode') },
                { max: 100, message: this.$t('field.supplierCompanyCode') + '长度小于等于100' },
                {type:'checkExists', existIdValue:_vm.supplierCompanyData.supplierCompanyId, checkField:'supplierCompanyCode', idFieldName: 'supplierCompanyId',tableName:'supplierCompany' }
              ]),
            } ]),expression:"[\n            'supplierCompanyData.supplierCompanyCode',\n            {\n              rules:( [\n                { required: true, message: '请输入' + this.$t('field.supplierCompanyCode') },\n                { max: 100, message: this.$t('field.supplierCompanyCode') + '长度小于等于100' },\n                {type:'checkExists', existIdValue:supplierCompanyData.supplierCompanyId, checkField:'supplierCompanyCode', idFieldName: 'supplierCompanyId',tableName:'supplierCompany' }\n              ]),\n            },\n          ]"}],attrs:{"name":"supplierCompanyData.supplierCompanyCode","placeholder":'请输入' + this.$t('field.supplierCompanyCode'),"maxLength":4},model:{value:(_vm.supplierCompanyData.supplierCompanyCode),callback:function ($$v) {_vm.$set(_vm.supplierCompanyData, "supplierCompanyCode", $$v)},expression:"supplierCompanyData.supplierCompanyCode"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.supplierCompanyName'),"labelCol":{ lg: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 10 }, sm: { span: 17 } }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'supplierCompanyData.supplierCompanyName',
            {
              rules: [
                { required: true, message: '请输入' + this.$t('field.supplierCompanyName') },
                { max: 100, message: this.$t('field.supplierCompanyName') + '长度小于等于100' } ],
            } ]),expression:"[\n            'supplierCompanyData.supplierCompanyName',\n            {\n              rules: [\n                { required: true, message: '请输入' + this.$t('field.supplierCompanyName') },\n                { max: 100, message: this.$t('field.supplierCompanyName') + '长度小于等于100' },\n              ],\n            },\n          ]"}],attrs:{"name":"supplierCompanyData.supplierCompanyName","placeholder":'请输入' + this.$t('field.supplierCompanyName')},model:{value:(_vm.supplierCompanyData.supplierCompanyName),callback:function ($$v) {_vm.$set(_vm.supplierCompanyData, "supplierCompanyName", $$v)},expression:"supplierCompanyData.supplierCompanyName"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.supplierCompanyPhone'),"labelCol":{ lg: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 10 }, sm: { span: 17 } }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'supplierCompanyData.supplierCompanyPhone',
            {
              rules: [
                { required: true, message: '请输入' + this.$t('field.supplierCompanyPhone') },
                { max: 100, message: this.$t('field.supplierCompanyPhone') + '长度小于等于100' } ],
            } ]),expression:"[\n            'supplierCompanyData.supplierCompanyPhone',\n            {\n              rules: [\n                { required: true, message: '请输入' + this.$t('field.supplierCompanyPhone') },\n                { max: 100, message: this.$t('field.supplierCompanyPhone') + '长度小于等于100' },\n              ],\n            },\n          ]"}],attrs:{"name":"supplierCompanyData.supplierCompanyPhone","placeholder":'请输入' + this.$t('field.supplierCompanyPhone')},model:{value:(_vm.supplierCompanyData.supplierCompanyPhone),callback:function ($$v) {_vm.$set(_vm.supplierCompanyData, "supplierCompanyPhone", $$v)},expression:"supplierCompanyData.supplierCompanyPhone"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.supplierCompanyContactName'),"labelCol":{ lg: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 10 }, sm: { span: 17 } }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'supplierCompanyData.supplierCompanyContactName',
            {
              rules: [
                { required: true, message: '请输入' + this.$t('field.supplierCompanyContactName') },
                { max: 100, message: this.$t('field.supplierCompanyContactName') + '长度小于等于100' } ],
            } ]),expression:"[\n            'supplierCompanyData.supplierCompanyContactName',\n            {\n              rules: [\n                { required: true, message: '请输入' + this.$t('field.supplierCompanyContactName') },\n                { max: 100, message: this.$t('field.supplierCompanyContactName') + '长度小于等于100' },\n              ],\n            },\n          ]"}],attrs:{"name":"supplierCompanyData.supplierCompanyContactName","placeholder":'请输入' + this.$t('field.supplierCompanyContactName')},model:{value:(_vm.supplierCompanyData.supplierCompanyContactName),callback:function ($$v) {_vm.$set(_vm.supplierCompanyData, "supplierCompanyContactName", $$v)},expression:"supplierCompanyData.supplierCompanyContactName"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.supplierCompanyContactPhone'),"labelCol":{ lg: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 10 }, sm: { span: 17 } }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'supplierCompanyData.supplierCompanyContactPhone',
            {
              rules: [
                { required: true, message: '请输入' + this.$t('field.supplierCompanyContactPhone') },
                { max: 100, message: this.$t('field.supplierCompanyContactPhone') + '长度小于等于100' } ],
            } ]),expression:"[\n            'supplierCompanyData.supplierCompanyContactPhone',\n            {\n              rules: [\n                { required: true, message: '请输入' + this.$t('field.supplierCompanyContactPhone') },\n                { max: 100, message: this.$t('field.supplierCompanyContactPhone') + '长度小于等于100' },\n              ],\n            },\n          ]"}],attrs:{"name":"supplierCompanyData.supplierCompanyContactPhone","placeholder":'请输入' + this.$t('field.supplierCompanyContactPhone')},model:{value:(_vm.supplierCompanyData.supplierCompanyContactPhone),callback:function ($$v) {_vm.$set(_vm.supplierCompanyData, "supplierCompanyContactPhone", $$v)},expression:"supplierCompanyData.supplierCompanyContactPhone"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.city'),"labelCol":{ lg: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 10 }, sm: { span: 17 } }}},[_c('a-cascader',{staticStyle:{"width":"100%"},attrs:{"options":_vm.codeList.cityList,"placeholder":"Please select","name":"city"},on:{"change":_vm.onChange}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }