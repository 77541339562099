import request from '@/utils/request'
import qs from 'qs'

const baseUrl = '/api'

export function saveSupplierCompanyData (parameter) {
  let url = baseUrl + '/system/supplierCompany/save'
  if (!parameter.supplierCompanyId) {
    url = baseUrl + '/system/supplierCompany/create'
  }
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function searchSupplierCompany (parameter) {
  return request({
    url: baseUrl + '/system/supplierCompany/search?' + qs.stringify(parameter),
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list,
      pageNo: res.data.pageInfo.pageNo ? res.data.pageInfo.pageNo : 1,
      totalCount: res.data.pageInfo.totalCount
    }
  })
}

export function listSupplierCompany (parameter) {
  return request({
    url: baseUrl + '/system/supplierCompany/list',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data
    }
  })
}

export function getSupplierCompanyData (parameter) {
  return request({
    url: baseUrl + '/system/supplierCompany/get',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function enableSupplierCompany (parameter) {
  return request({
    url: baseUrl + '/system/supplierCompany/enable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function disableSupplierCompany (parameter) {
  return request({
    url: baseUrl + '/system/supplierCompany/disable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function deleteSupplierCompany (parameter) {
  return request({
    url: baseUrl + '/system/supplierCompany/delete',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}
