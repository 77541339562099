<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline" ref="queryForm">
        <a-row :gutter="24">
          <a-col :md="8" :sm="24">
            <a-form-item
              :label="$t('field.supplierCompanyCode')"
              :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
            >
              <a-input
                v-decorator="[
                  'params.supplierCompanyCode',
                  {
                    rules: [
                      { required: true, message: '请输入' + this.$t('field.supplierCompanyCode') },
                      { max: 100, message: this.$t('field.supplierCompanyCode') + '长度小于等于100' },
                    ],
                  },
                ]"
                name="params.supplierCompanyCode"
                v-model="params.supplierCompanyCode"
                :placeholder="'请输入' + this.$t('field.supplierCompanyCode')"
              />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item
              :label="$t('field.supplierCompanyName')"
              :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
            >
              <a-input
                v-decorator="[
                  'params.supplierCompanyName',
                  {
                    rules: [
                      { required: true, message: '请输入' + this.$t('field.supplierCompanyName') },
                      { max: 100, message: this.$t('field.supplierCompanyName') + '长度小于等于100' },
                    ],
                  },
                ]"
                name="params.supplierCompanyName"
                v-model="params.supplierCompanyName"
                :placeholder="'请输入' + this.$t('field.supplierCompanyName')"
              />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item
              :label="$t('field.supplierCompanyPhone')"
              :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
            >
              <a-input
                v-decorator="[
                  'params.supplierCompanyPhone',
                  {
                    rules: [
                      { required: true, message: '请输入' + this.$t('field.supplierCompanyPhone') },
                      { max: 100, message: this.$t('field.supplierCompanyPhone') + '长度小于等于100' },
                    ],
                  },
                ]"
                name="params.supplierCompanyPhone"
                v-model="params.supplierCompanyPhone"
                :placeholder="'请输入' + this.$t('field.supplierCompanyPhone')"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24" v-if="showAdvancedSearchCondition">
          <a-col :md="8" :sm="24">
            <a-form-item
              :label="$t('field.supplierCompanyContactName')"
              :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
            >
              <a-input
                v-decorator="[
                  'params.supplierCompanyContactName',
                  {
                    rules: [
                      { required: true, message: '请输入' + this.$t('field.supplierCompanyContactName') },
                      { max: 100, message: this.$t('field.supplierCompanyContactName') + '长度小于等于100' },
                    ],
                  },
                ]"
                name="params.supplierCompanyContactName"
                v-model="params.supplierCompanyContactName"
                :placeholder="'请输入' + this.$t('field.supplierCompanyContactName')"
              />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item
              :label="$t('field.supplierCompanyContactPhone')"
              :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
            >
              <a-input
                v-decorator="[
                  'params.supplierCompanyContactPhone',
                  {
                    rules: [
                      { required: true, message: '请输入' + this.$t('field.supplierCompanyContactPhone') },
                      { max: 100, message: this.$t('field.supplierCompanyContactPhone') + '长度小于等于100' },
                    ],
                  },
                ]"
                name="params.supplierCompanyContactPhone"
                v-model="params.supplierCompanyContactPhone"
                :placeholder="'请输入' + this.$t('field.supplierCompanyContactPhone')"
              />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item
              :label="$t('field.country')"
              :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
            >
              <a-input
                v-decorator="[
                  'params.country',
                  {
                    rules: [
                      { required: true, message: '请输入' + this.$t('field.country') },
                      { max: 100, message: this.$t('field.country') + '长度小于等于100' },
                    ],
                  },
                ]"
                name="params.country"
                v-model="params.country"
                :placeholder="'请输入' + this.$t('field.country')"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24" v-if="showAdvancedSearchCondition">
          <a-col :md="8" :sm="24">
            <a-form-item
              :label="$t('field.state')"
              :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
            >
              <a-input
                v-decorator="[
                  'params.state',
                  {
                    rules: [
                      { required: true, message: '请输入' + this.$t('field.state') },
                      { max: 100, message: this.$t('field.state') + '长度小于等于100' },
                    ],
                  },
                ]"
                name="params.state"
                v-model="params.state"
                :placeholder="'请输入' + this.$t('field.state')"
              />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item
              v-if="showAdvancedSearchCondition"
              :label="$t('field.city')"
              :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
            >
              <a-input
                v-decorator="[
                  'params.city',
                  {
                    rules: [
                      { required: true, message: '请输入' + this.$t('field.city') },
                      { max: 100, message: this.$t('field.city') + '长度小于等于100' },
                    ],
                  },
                ]"
                name="params.city"
                v-model="params.city"
                :placeholder="'请输入' + this.$t('field.city')"
              />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item
              v-if="showAdvancedSearchCondition"
              :label="$t('field.status')"
              :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
              :required="false"
            >
              <a-select :allowClear="true" mode="default" v-model="params.status">
                <a-select-option
                  v-for="(item, index) in codeList.status"
                  :key="index"
                  :value="item.value"
                >{{ item.label }}
                </a-select-option>
              </a-select></a-form-item>
          </a-col>
          <!-- <a-col :md="5" :sm="24">
            <a-form-item label="更新日期">
              <a-date-picker v-model="params.date" style="width: 100%" placeholder="请输入更新日期" />
            </a-form-item>
          </a-col> -->
        </a-row>
        <a-row>
          <a-col :md="24" :sm="24">
            <span
              class="table-page-search-submitButtons"
              :style="(showAdvancedSearchCondition && { overflow: 'hidden' }) || {}"
            >
              <a-button icon="plus" type="primary" @click="$emit('handleAdd')">{{ $t('button.create') }}</a-button>
              <a-divider type="vertical" />
              <a-button icon="search" type="primary" @click="queryData">{{ $t('button.search') }}</a-button
              ><a-divider type="vertical" />
              <a-button style="margin-left: 8px" @click="resetData">{{ $t('button.reset') }}</a-button>
              <a @click="toggleAdvancedSearch" style="margin-left: 8px">
                {{ showAdvancedSearchCondition ? $t('button.collapseIn') : $t('button.collapseOut') }}
                <a-icon :type="showAdvancedSearchCondition ? 'up' : 'down'" />
              </a>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'SupplierCompanySearch',
  components: {},
  props: {
    // 查询参数
    queryParam: {
      type: Object
    }
    // showAdvancedSearchCondition: {
    //   type: Boolean,
    // },
  },
  watch: {
    queryParam: {
      handler (val) {
        this.params = val
      },
      deep: true
    }
  },
  data () {
    return {
      codeList: {
        supplierCompanyCode: [],
        country: []
      },
      params: {},
      showAdvancedSearchCondition: false
    }
  },
  created () {},
  mounted () {},
  methods: {
    toggleAdvancedSearch () {
      this.showAdvancedSearchCondition = !this.showAdvancedSearchCondition
    },
    resetData () {
      this.params = {}
      this.$emit('resetData', this.params)
    },
    queryData () {
      this.$emit('resetData', this.params)
      // this.$parent.$parent.$refs.table.refresh(true)
    }
  }
}
</script>
